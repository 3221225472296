<script>
import { mapGetters } from 'vuex'
import { GET_TEMPLATE } from '../store/actions'
import IconArrowBreadCrumbs from '@/assets/icon-arrow-bread-crumbs.vue'

export default {
  name: 'ViewTemplateIndex',
  components: {
    IconArrowBreadCrumbs,
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const option = this.$route.name
      if (this.$te(`issue.${option}`)) { return this.$t(`issue.${option}`) }

      return null
    },
    ...mapGetters(['cid']),
    ...mapGetters('template', ['credName']),
  },
  created() {
    this.debug(`*** ${this.$options.name} ***`)
  },
  mounted() {
    console.log(this.$route)
    this.getTemplate()
  },
  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    console.log('Clear template')
    this.$store.dispatch('template/CLEAR_TEMPLATE')
  },
  methods: {
    async getTemplate() {
      if (this.$route.params?.tid) {
        this.debug('Call GET_TEMPLATE action')
        const template = await this.$store.dispatch(GET_TEMPLATE, {
          tid: this.$route.params.tid,
        })
        if (template) {
          await this.$store.dispatch('template/SET_TEMPLATE', {
            template,
          })
        }
      }
    },
  },

}
</script>

<template>
  <v-container class="view-credential">
    <v-row>
      <v-col cols="12" class="pt-7 pb-0 breadcrumbs">
        <div @click="$router.push('/Dashboard')">
          <p style="margin: 2px">
            {{ $t('dashboard.title') }}
          </p>
        </div>
        <div
          :class="{ active: !currentPage }"
          @click="currentPage && $router.go(-1)"
        >
          <IconArrowBreadCrumbs style="margin: 0px 6px 2px 6px" />
          <p style="margin: 2px 6px 2px 2px">
            {{ credName }}
          </p>
        </div>
        <div v-if="currentPage" :class="{ active: currentPage }">
          <IconArrowBreadCrumbs style="margin: 0px 6px 2px 6px" />
          <p style="margin: 2px 6px 2px 2px">
            {{ currentPage }}
          </p>
        </div>
      </v-col>
      <v-col cols="12" class="pt-2">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<style></style>
